import React from "react";
import {Helmet} from "react-helmet";
import {withPrefix} from "gatsby";


import "bootstrap/dist/css/bootstrap.css";
import "../../css/global.scss";


const VouchedLayout = ({children}) => {
    return (
        <>
            <Helmet>
                <html lang="en"/>
                <script defer src="https://static.vouched.id/widget/vouched-2.0.0.js"/>
                <script defer src="/js/vouched.js"/>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix("/")}img/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix("/")}img/favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix("/")}img/favicon-16x16.png`}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
                    color="#ff4400"
                />

                <meta name="theme-color" content="#fff"/>

                <meta property="og:type" content="business.business"/>

                <meta property="og:url" content="/"/>
                <meta
                    property="og:image"
                    content={`${withPrefix("/")}img/og-image.jpg`}
                />

            </Helmet>

            {children}

        </>
    );
};

export default VouchedLayout;
