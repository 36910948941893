import React from "react";

import {Container } from "react-bootstrap";

import LayoutVouched from "../../components/LayoutVouched";



import styles from'./index.module.scss';


const VerificationPageStep2 = () => {
  return (
    
    <LayoutVouched>
      <Container className={styles.fullcontainer}> 
        <div  className={styles.verification} id="vouched-element" styles="height: 75%; "/> 
      </Container>
    </LayoutVouched>
  );
};

export default VerificationPageStep2;


